import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faDownload,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CarouselProps {
  picture: any;
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

const Carousel = ({ picture, onClose, onNext, onPrevious }: CarouselProps) => {
  return (
    <div className="flex flex-col justify-center items-center text-white font-bold fixed top-0 left-0 w-screen h-screen z-20 backdrop-blur-md backdrop-brightness-50">
      <div
        className="flex justify-between items-center text-2xl lg:text-3xl xl:text-4xl h-full w-full py-10"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <FontAwesomeIcon
          icon={faCircleChevronLeft}
          className="drop-shadow-md m-5 md:m-10 2xl:m-30 hover:animate-pulse"
          onClick={(e) => {
            e.stopPropagation();
            onPrevious();
          }}
        />
        <img
          className="shadow-xl rounded-2xl w-4/6 xl:w-5/6 2xl:w-auto max-w-full max-h-full"
          src={`https://lh3.googleusercontent.com/d/${picture.ID}=s1280?authuser=0`}
          alt={picture.Name}
        />
        <FontAwesomeIcon
          icon={faCircleChevronRight}
          className="drop-shadow-md m-5 md:m-10 2xl:m-30 hover:animate-pulse"
          onClick={(e) => {
            e.stopPropagation();
            onNext();
          }}
        />
      </div>
      <div className="absolute bottom-14 flex justify-center gap-5">
        <div
          onClick={() => {
            window
              .open(
                `https://drive.google.com/file/d/${picture.ID}/view?usp=drivesdk`,
                '_blank'
              )
              ?.focus();
          }}
          className="flex flex-col justify-center items-center gap-2 p-5 rounded-xl backdrop-blur-xl hover:animate-pulse"
        >
          <FontAwesomeIcon icon={faDownload} className="text-3xl text-white" />
          <span>Télécharger</span>
        </div>
        {navigator.share !== undefined && (
          <div
            onClick={() => {
              navigator.share({
                title: picture.Name,
                text: picture.Name,
                url: picture.URL,
              });
            }}
            className="flex flex-col justify-center items-center gap-2 p-5 rounded-xl backdrop-blur-xl hover:animate-pulse"
          >
            <FontAwesomeIcon icon={faShare} className="text-3xl text-white" />
            <span>Partager</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
