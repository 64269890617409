import { useState } from 'react';
import Home from './views/Home';
import Welcome from './views/Welcome';

function App() {
  const [home, setHome] = useState(false);

  return <>{home ? <Home /> : <Welcome onClick={() => setHome(true)} />}</>;
}

export default App;
