import { useRef } from 'react';
import { useIntersection } from 'react-use';
import styles from './Menu.module.css';

interface MenuProps {
  menus: any[];
  selectedMenu: number;
  onMenuChange: (selectedMenu: number) => void;
}

const Menu = ({ menus, selectedMenu, onMenuChange }: MenuProps) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: document,
    rootMargin: '-20px',
    threshold: 1,
  });

  return (
    <div
      ref={intersectionRef}
      className={`${styles.menu} ${
        intersection && intersection.intersectionRatio < 1
          ? styles.retracted
          : ''
      }`}
    >
      {menus.map((menu, i) => (
        <div
          className={`${styles.menuItem} ${
            selectedMenu === i ? styles.active : ''
          }`}
          style={{
            backgroundImage: `url(https://lh3.googleusercontent.com/d/${menu.ID}=s1280?authuser=0)`,
          }}
          onClick={() => onMenuChange(i)}
          key={menu.API}
        >
          <h2 className={styles.title}>{menu.Label}</h2>
        </div>
      ))}
    </div>
  );
};

export default Menu;
