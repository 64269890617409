import styles from './Button.module.css';

interface ButtonProps {
  label: React.ReactNode;
  onClick: () => void;
}

const Button = ({ label, onClick }: ButtonProps) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {label}
      <span className={styles.horizontal}></span>
      <span className={styles.vertical}></span>
    </button>
  );
};

export default Button;
