import { faRing } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Carousel from '../Carousel/Carousel';
import ImageLoader from '../ImageLoader/ImageLoader';
import styles from './Masony.module.css';

interface MasonyProps extends React.HTMLAttributes<HTMLElement> {
  endpoint: string;
}

const Masonry = ({ id, endpoint }: MasonyProps) => {
  const [pictures, setPictures] = useState([]);
  const [carousel, setCarousel] = useState(false);
  const [currentPicture, setCurrentPicture] = useState<number>(0);

  useEffect(() => {
    fetch(
      `https://script.google.com/macros/s/AKfycbyQdFc4tPL-E9xjOoisxPY_Y6QQxdtBIYjG67EzcvKMiasPvYvU3T19RiIAssA_aug7XQ/exec?path=${endpoint}`
    )
      .then((res) => res.json())
      .then((json) => {
        setPictures(json);
      });
  }, [endpoint]);

  return pictures.length > 0 ? (
    <div id={id} className={styles.masonry}>
      {carousel && (
        <Carousel
          onClose={() => setCarousel(false)}
          onNext={() =>
            setCurrentPicture(
              currentPicture >= pictures.length - 1 ? 0 : currentPicture + 1
            )
          }
          onPrevious={() =>
            setCurrentPicture(
              currentPicture <= 0 ? pictures.length - 1 : currentPicture - 1
            )
          }
          picture={pictures[currentPicture]}
        />
      )}
      {pictures.map((picture: any, i) => (
        <ImageLoader
          key={picture.ID}
          id={picture.ID}
          name={picture.Name}
          onClick={() => {
            setCurrentPicture(i);
            setCarousel(true);
          }}
        />
      ))}
    </div>
  ) : (
    <div className="w-full h-full flex justify-center text-pink-300 text-6xl">
      <FontAwesomeIcon icon={faRing} className="animate-pulse m-12" />
    </div>
  );
};

export default Masonry;
