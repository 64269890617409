import { useEffect } from 'react';
import styles from './SlideTransition.module.css';

interface SlideTransitionProps {
  active: boolean;
  images: any[];
  onSlideEnd: () => void;
  children?: JSX.Element;
}

const SlideTransition = ({
  active,
  onSlideEnd,
  images,
  children,
}: SlideTransitionProps) => {
  useEffect(() => {
    if (active) {
      setTimeout(onSlideEnd, 2000);
    }
  }, [active, onSlideEnd]);

  return (
    <div className={`${styles.slides} ${active ? styles.active : ''}`}>
      {images.map((image, i) => (
        <div className={styles.slideContainer} key={image.ID}>
          <div
            className={styles.slide}
            style={{
              backgroundImage: `url(https://lh3.googleusercontent.com/d/${image.ID}=s1280?authuser=0)`,
            }}
          >
            {i >= images.length - 1 ? children : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SlideTransition;
