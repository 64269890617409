import { faRing } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Button from '../components/Button/Button';
import Logo from '../components/Logo/Logo';
import SlideTransition from '../components/SlideTransition/SlideTransition';

interface WelcomeProps {
  onClick: () => void;
}

const Welcome = ({ onClick }: WelcomeProps) => {
  const [active, setActive] = useState(false);
  const [transitionImg, setTransitionImg] = useState<any>([]);

  useEffect(() => {
    fetch(
      'https://script.google.com/macros/s/AKfycbyQdFc4tPL-E9xjOoisxPY_Y6QQxdtBIYjG67EzcvKMiasPvYvU3T19RiIAssA_aug7XQ/exec?path=Transition'
    )
      .then((res) => res.json())
      .then((json) => setTransitionImg(json));
  }, []);

  return transitionImg.length > 0 ? (
    <SlideTransition
      active={active}
      onSlideEnd={onClick}
      images={transitionImg}
    >
      <div className="flex flex-col justify-center items-center w-screen h-screen bg-black">
        <Logo />
        <div className="m-12">
          <Button label="ENTRER" onClick={() => setActive(!active)} />
        </div>
      </div>
    </SlideTransition>
  ) : (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <Logo />
      <div className="m-12">
        <Button
          label={
            <FontAwesomeIcon icon={faRing} className="animate-pulse text-4xl" />
          }
          onClick={() => setActive(!active)}
        />
      </div>
    </div>
  );
};

export default Welcome;
