import styles from './Logo.module.css';

const Logo = () => {
  return (
    <div className={styles.logo}>
      <h1 className={`${styles.letter} relative left-3 bottom-3`}>M</h1>
      <div className={styles.spacer}>
        <h1 className={`${styles.letter} relative`}>|</h1>
      </div>
      <h1 className={`${styles.letter} relative right-3 top-6`}>H</h1>
    </div>
  );
};

export default Logo;
