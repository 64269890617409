import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './Hero.module.css';
interface HeroProps {
  infos: any;
}

const Hero = ({ infos }: HeroProps) => {
  const [mute, toggleMute] = useState(false);

  return (
    <div
      className={styles.hero}
      onClick={() => window?.open(infos.API, '_blank')?.focus()}
    >
      <ReactPlayer
        url={infos.API}
        className={styles.video}
        width="100vw"
        height="100vh"
        playing={true}
        loop={true}
        volume={0.5}
        muted={mute}
      />
      <div className={styles.banner}>
        <h1 className={styles.title}>{infos.Label}</h1>
        <p className={styles.content}>{infos.Description}</p>
      </div>
      <FontAwesomeIcon
        icon={mute ? faVolumeMute : faVolumeUp}
        className="text-2xl md:text-3xl text-white absolute md:bottom-5 md:right-5 bottom-0 right-0 cursor-pointer hover:animate-pulse p-5"
        onClick={(e) => {
          e.stopPropagation();
          toggleMute(!mute);
        }}
      />
    </div>
  );
};

export default Hero;
