import { useState } from 'react';
import styles from './ImageLoader.module.css';

interface ImageLoaderProps {
  id: string;
  name: string;
  onClick: () => void;
}

const ImageLoader = ({ id, name, onClick }: ImageLoaderProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div className={styles.imageWrapper}>
      <img
        className={loaded ? styles.image : 'hidden'}
        src={`https://lh3.googleusercontent.com/d/${id}=s1280?authuser=0`}
        alt={name}
        onLoad={() => setLoaded(true)}
        onClick={onClick}
      />
      <div className={loaded ? 'hidden' : styles.placeholder}></div>
    </div>
  );
};

export default ImageLoader;
