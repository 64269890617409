import { useEffect, useState } from 'react';
import Hero from '../components/Hero/Hero';
import Masonry from '../components/Masonry/Masonry';
import Menu from '../components/Menu/Menu';

const Home = () => {
  const [menus, setMenus] = useState<any[]>([]);
  const [hero, setHero] = useState<any>(null);
  const [selectedMenu, setSelectedMenu] = useState(0);

  useEffect(() => {
    document.getElementById('mouseCanva')?.remove();

    fetch(
      'https://script.google.com/macros/s/AKfycbyQdFc4tPL-E9xjOoisxPY_Y6QQxdtBIYjG67EzcvKMiasPvYvU3T19RiIAssA_aug7XQ/exec?path=Main'
    )
      .then((res) => res.json())
      .then((json) => {
        setHero(json[0]);
        setMenus(json.slice(1));
      });
  }, []);

  return (
    <div className="p-5">
      {hero && <Hero infos={hero} />}
      <Menu
        menus={menus}
        selectedMenu={selectedMenu}
        onMenuChange={(i) => {
          document.getElementById('pictures')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
          setSelectedMenu(i);
        }}
      />
      {menus.length > 0 && (
        <Masonry id="pictures" endpoint={menus[selectedMenu].API as string} />
      )}
    </div>
  );
};

export default Home;
